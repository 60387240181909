import {navigate} from 'hookrouter';
import {useEffect, useState} from 'react';

import {ssprefix,endpoint} from './Config'

export default function Start(params) {

  const [infoProgetto,setInfoProgetto] = useState(false);
  const [errore, setErrore] = useState(false);

  useEffect(() => {
    fetch(endpoint + '&task=apply.getProgetto&uid='+params.uid)
      .then(response => {
        if (!response.ok) {
          alert("Errore");
        } else  {
          return response.json();
        }
      })
      .then(data => {

        if (data.error) {
          setErrore(true);
          return;
        } else {
          sessionStorage.setItem(ssprefix+"progetto",JSON.stringify(data))
          setInfoProgetto(data);
          document.getElementsByTagName("title")[0].innerHTML = data.titolo;
        }

        
        
      });

  },[params.uid])

    let periodo = (<div className="ssc-line"></div>);
    let stato_raccolta = (<span className="ssc-line"></span>);
    let vai_avanti = false;

    if (infoProgetto!==false) {
      let dal = new Date(infoProgetto.periodo.dal);
      let al = new Date(infoProgetto.periodo.al);
      dal.setHours(0,0,0,0);
      al.setHours(23,59,59,999);
      periodo = (<><i className="bi bi-calendar-range"></i> Iscrizioni aperte dal {dal.toLocaleDateString("it-it")} al {al.toLocaleDateString("it-it")}</>);

      let now = new Date();

      if (dal <= now && al >= now) {
        stato_raccolta = (<><span className="text-success"><i className="bi bi-check-circle-fill"></i></span> In corso</>)
        vai_avanti = true;
      } else if (dal > now) {
        stato_raccolta = (<><span className=""><i className="bi bi-x-circle"></i></span> Non ancora iniziata</>)
      } else if (al < now) {
        stato_raccolta = (<><span className=""><i className="bi bi-x-circle"></i></span> Conclusa</>)
      }
    }

    const next = () => {
      let stato_sezioni = JSON.parse(sessionStorage.getItem(ssprefix+"statosezioni"));
      stato_sezioni[0] = true;
      sessionStorage.setItem(ssprefix+"statosezioni",JSON.stringify(stato_sezioni))
      navigate("/"+params.uid+"/scuola")
    }

    if (errore) {
      return (
        <div className="sp_form">
          <h1>Errore</h1>
          <p>Il link che hai utilizzato non è valido, oppure il portale iscrizioni è temporaneamente non funzionante.</p>
        </div>
      )
    } else 
    return(
        <div class="sp_form">
          <div className={(infoProgetto===false?"ssc-square":"")+" mb-3"} >
            <img alt="Banner" src={infoProgetto.banner} style={{width:"100%"}}></img>
          </div>

          <h1 className={(infoProgetto===false?"ssc-head-line":"")}>{infoProgetto.titolo}</h1>
          <p>
            { infoProgetto === false ?
              (<div class="ssc-wrapper">
              <div class="ssc-line w-90"></div>
              <div class="ssc-line w-30"></div>
              <div class="ssc-line w-70"></div>
              <div class="ssc-line w-50"></div>
          </div>)
            : infoProgetto.sottotitolo
          }
          </p>

          <div class="alert alert-info w-100"><h5>Raccolta delle adesioni</h5>
          {stato_raccolta}<br/>
          {periodo}
          </div>
         
          {vai_avanti === true ? <button onClick={next} className="btn btn-lg btn-primary"><i className="bi bi-arrow-right"></i> Avanti</button> : "" }
          
        </div>
    )
}