import logo from './images/logo.png';
import {usePath} from 'hookrouter';
import { ssprefix } from './Config';
export default function Sidebar() {

    const sections = [
        {path : '/', name : 'Inizio'},
        {path : '/scuola', name : 'La tua scuola'},
        {path : '/anagrafica-docente', name : 'I tuoi dati'},
        {path : '/classi', name : 'Le tue classi'},
        {path : '/utente-scuolapark', name : 'Il tuo account'},
        {path : '/privacy', name : 'Privacy'},
        {path : '/riepilogo', name : 'Riepilogo e invio'},
        {path : '/invio-completato', name : 'Invio completato'},
    ]
    const path = usePath();

    const stato_sezioni = JSON.parse(sessionStorage.getItem(ssprefix+"statosezioni"));
    
    const s ={};
    if (window.location !== window.parent.location) {
        s.display = "none"
    }

    return (
    <aside>
        <div style={s} id="logo-container" className="text-center pt-5">
         <img alt="Achab Group" src={logo} className="sp_logo"></img>
        </div>


        <ul id="elenco-sezioni" className="list-group list-group-flush">
            {
                sections.map((sec, key) => {
                    let cn = "list-group-item d-flex justify-content-between";
                    console.log(path)
                    if (path.endsWith(sec.path) || (key === 0 && path.split("/").length === 2)) {

                        cn += " current";
                        
                        return (
                            <li key={key} className={cn}><b>{key+1}. {sec.name}</b> {stato_sezioni[key]===true?(<i className="bi bi-check-circle text-success"></i>):''}</li>
                        );
                    } else {

                        return (
                            <li key={key} className={cn}><span>{key+1}. {sec.name}</span> {stato_sezioni[key]===true?(<i className="bi bi-check-circle text-success"></i>):''}</li>
                        );
                    }
                    
                })
            }
        </ul>

        <div className="text-center" style={{fontSize: '.85rem'}}>
            <p>
                <a href="https://www.iubenda.com/privacy-policy/36942744">Informativa sulla Privacy</a><br/>
                <a href="https://www.achabgroup.it/">Achab srl SB</a>
            </p>
        </div>
    </aside>
    )
}