import {navigate} from 'hookrouter';
import { useState } from 'react';
import { ssprefix, endpoint } from './Config';

export default function Riepilogo(params) {

  const [sending,setSending] = useState(false);

  const all_data = {}
  all_data.anagrafica_docente = JSON.parse(sessionStorage.getItem(ssprefix+"anagraficadocente"));
  all_data.utente_scuolapark = JSON.parse(sessionStorage.getItem(ssprefix+"utentescuolapark"));
  all_data.comune = JSON.parse(sessionStorage.getItem(ssprefix+"comuneselezionato"));
  all_data.classi = JSON.parse(sessionStorage.getItem(ssprefix+"elencoclassi"));
  all_data.scuola = JSON.parse(sessionStorage.getItem(ssprefix+"scuola"));
  all_data.privacy = JSON.parse(sessionStorage.getItem(ssprefix+"privacy"));
  all_data.uid = params.uid;

  if (all_data.scuola.inserimento_manuale) {


  } else {
    const tmp = {};
    tmp.istituto = all_data.scuola.DENOMINAZIONEISTITUTORIFERIMENTO;
    tmp.codice_istituto = all_data.scuola.CODICEISTITUTORIFERIMENTO;
    tmp.plesso = all_data.scuola.DENOMINAZIONESCUOLA;
    tmp.codice_plesso = all_data.scuola.CODICESCUOLA;
    tmp.indirizzo = all_data.scuola.INDIRIZZOSCUOLA;
    tmp.telefono = all_data.scuola.TELEFONOSCUOLA;
    tmp.email = all_data.scuola.INDIRIZZOEMAILSCUOLA;
    all_data.scuola = tmp;
  }
  
  const submit = (e) => {
        e.preventDefault();
        
        setSending(true);

        const fd = new FormData();

        fd.append("A[task]","apply/add");
        fd.append("A[data]",JSON.stringify(all_data));

        const xhr = new XMLHttpRequest();
        xhr.open("POST", endpoint, true);

        //Send the proper header information along with the request

        xhr.onreadystatechange = function() { // Call a function when the state changes.
            if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
              const response = JSON.parse(this.response);
              console.log(response)
              if (response.A.ok === true) {
                
                let stato_sezioni = JSON.parse(sessionStorage.getItem(ssprefix+"statosezioni"));
                stato_sezioni[6] = true;
                sessionStorage.setItem(ssprefix+"statosezioni",JSON.stringify(stato_sezioni))
                navigate("./invio-completato")
              }

              if (response.A.error) {
                alert(response.A.error);
                setSending(false);
              }
            }
        }
        xhr.send(fd);

  }
  
    return(
        <div className="sp_form">
            <form onSubmit={submit}>
            <h2>Riepilogo ed invio</h2>
            <p>Controlla i dati inseriti ed invia il modulo.</p>

            <p><span className="badge bg-secondary" style={{fontSize:"1rem"}}>I tuoi dati</span> {all_data.anagrafica_docente.nome} {all_data.anagrafica_docente.cognome}, indirizzo email {all_data.anagrafica_docente.email}, telefono {all_data.anagrafica_docente.telefono}</p>

            <p><span className="badge bg-secondary" style={{fontSize:"1rem"}}>Le tue classi</span> {all_data.classi.map((cl) => cl.classe).join(", ")}.</p>

            <p><span className="badge bg-secondary" style={{fontSize:"1rem"}}>La tua scuola</span> {all_data.scuola.istituto}, {all_data.scuola.indirizzo} ({all_data.comune.label.toUpperCase()}).</p>

            <p>Invieremo all'indirizzo <code>{all_data.anagrafica_docente.email}</code> un messaggio di conferma con tutti i dati inseriti.</p>

            <hr/>
            

            <p className="mt-3"><button disabled={sending} type="submit" className="btn btn-primary"><i className="bi bi-check2-all"></i> Invia la domanda</button></p>

           </form>
        </div>
    )
}