import {navigate} from 'hookrouter';
import { ssprefix } from './Config';
import {Formik,  Form, Field} from 'formik';


export default function Privacy(params) {

  const form_name = ssprefix+"privacy";
  let initial = {};
  if (sessionStorage.getItem(form_name) !== null) {
    initial = JSON.parse(sessionStorage.getItem(form_name));
  }

  const progetto = JSON.parse(sessionStorage.getItem(ssprefix+"progetto"));

  let privacy_cliente = "";
  if (progetto.privacy_cliente !== null) {
    privacy_cliente = (
      <div className="card mt-3">
        <div className="card-body">
          <h5 className="card-title">{progetto.cliente} | Privacy</h5>

          <p><a target="_blank" href={progetto.privacy_cliente}>Scarica l'<b>informativa sul trattamento dei dati personali</b> predisposta da <b>{progetto.cliente}</b></a></p>

          <div className="form-check mt-3">
              <Field type="checkbox" name="ack-privacycliente" required value="ok" id="ack-privacycliente" required className="form-check-input" />
              <label className="form-check-label" for="ack-privacycliente">
              Confermo di aver preso visione dell'informativa sopra riportata
              </label>
          </div>
          
        </div>
      </div>
    )
  }

  return(
    <div class="sp_form">
   
      <Formik
        initialValues={initial}
        validate={values => {
          // const errors = {}
          
          // if (values.password.length < 5) {
          //   errors.password = "Password troppo corta!";
          // }

          // return errors;
          return {};
        }}

        onSubmit={(values, { setSubmitting }) => {

          setTimeout(() => {
 
            sessionStorage.setItem(form_name,JSON.stringify(values))
            let stato_sezioni = JSON.parse(sessionStorage.getItem(ssprefix+"statosezioni"));
            stato_sezioni[5] = true;
            sessionStorage.setItem(ssprefix+"statosezioni",JSON.stringify(stato_sezioni))
 
            setSubmitting(false);

            navigate("./riepilogo")
 
          }, 400);
 
        }}>
          {
            (props) => (
              <Form>

                { parseInt(progetto.privacy_foto) === 0 ? "" : (<>
               <h2>Autorizzazione foto e riprese video degli studenti</h2>
            <p>L'eventuale assenza delle autorizzazioni necessarie (per tutti gli studenti della classe) non preclude la partecipazione alle attività didattiche previste.</p>

            <div className="card">
                <div className="card-body">
                    <p>Foto e/o riprese video degli alunni non avranno alcun fine lucrativo ma scopi puramente educativo-didattici (ad esempio materiali didattici, sito web aziendale, social network, giornali locali). Le classi dispongono già dell’autorizzazione dei genitori alla realizzazione di fotografie e riprese video?</p>

                    <div className="form-check">
                        <Field type="radio" name="autorizz-foto" value="y" id="autorizz-foto-si" required className="form-check-input" />
                        <label className="form-check-label" for="autorizz-foto-si">
                       Sì
                        </label>
                    </div>
                    <div className="form-check">
                        <Field type="radio" name="autorizz-foto" value="n" id="autorizz-foto-no" required className="form-check-input" />
                        <label className="form-check-label" for="autorizz-foto-no">
                      No
                        </label>
                    </div>
                
                </div>
            </div>

            <hr className="my-5"/>
            </>) }

            <h2>Autorizzazione al trattamento dei dati personali</h2>
            <p>Il tuo consenso è obbligatorio per noi per poter raccogliere i dati necessari all'iscrizione della tua classe (il tuo nominativo, indirizzo mail e numero di telefono personali) e per poterti inviare le successive comunicazioni per la programmazione delle attività didattiche.</p>

            <div className="card">
                <div className="card-body">
                    <div className="form-check">
                         <Field type="checkbox" name="autorizz-privacy" value="ok" id="autorizz-privacy" required className="form-check-input" />
                        <label className="form-check-label" for="autorizz-privacy">
                        Dichiaro di aver preso visione di tutte le informazioni relative al progetto e di aver letto l'offerta didattica contenente le attività proposte
                        </label>
                    </div>

                    <div className="form-check mt-3">
                        <Field type="checkbox" name="ack-priorita" value="ok" id="ack-priorita" required className="form-check-input" />
                        <label className="form-check-label" for="ack-priorita">
                        Dichiaro di aver preso atto e compreso che i posti per le attività didattiche sono limitati e che sarà tenuto in considerazione l'ordine di registrazione delle adesioni.
                        </label>
                    </div>
                   
                
                </div>
            </div>

            <div className="card mt-3">
                <div className="card-body">

                    <p style={{fontSize:".85rem"}}>TRATTAMENTO DEI DATI ai sensi del Regolamento UE 2016/679 e del D. Lgs. 101/2018 in materia di protezione dei dati personali ti informiamo di quanto segue. <b>Finalità del trattamento e base giuridica</b>: I tuoi dati personali saranno utilizzati  esclusivamente per lo svolgimento dell’attività statistica in oggetto e per migliorare la qualità del servizio. La base giuridica del trattamento è rappresentata dal consenso espresso con la presente adesione e avverrà nel rispetto della normativa vigente nonché dei principi di liceità, correttezza e trasparenza, adeguatezza e pertinenza, con modalità cartacee ed informatiche, ad opera di persone autorizzate e con l’adozione di misure adeguate di protezione, in modo da garantire la sicurezza e la riservatezza dei dati. Non verrà svolto alcun processo decisionale automatizzato. Necessità, durata e diffusione dei dati: Il conferimento dei dati anagrafici e di contatto è necessario in quanto strettamente legato alla gestione delle richieste; tali dati saranno cancellati dopo max 24 mesi. I dati raccolti saranno usati esclusivamente ai fini della presente campagna informativa e non saranno ceduti a terzi per altri scopi. <b>Diritti dell’interessato</b>: Ti sono garantiti tutti i diritti previsti, tra cui il diritto all’accesso, rettifica e cancellazione dei dati, il diritto di limitazione e opposizione al trattamento, il diritto di revocare il consenso al trattamento. I suddetti diritti possono essere esercitati mediante comunicazione scritta da inviare a mezzo posta elettronica a privacy@achabgroup.it.</p>
                    <div className="form-check">
                        <Field type="checkbox" name="autorizz-gdpr" value="ok" id="autorizz-gdpr" required className="form-check-input" />
                        <label className="form-check-label" for="autorizz-gdpr">
                        Accetto
                        </label>
                    </div>

                
                </div>
            </div>

            { privacy_cliente }

            <p className="mt-3"><button type="submit" className="btn btn-primary"><i className="bi bi-arrow-right"></i> Vai al riepilogo</button></p>

              </Form>
            )
          }
        </Formik>      
    </div>
)

}