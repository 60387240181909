import { navigate } from 'hookrouter';
export default function InvioCompletato(props) {

    return (

        <div className="sp_form">
            <h1 className="text-center d-none d-md-block">La tua domanda è stata inviata</h1>

            <div className="row">
                <div className="col-md-6 py-3 text-center">
                    <button onClick={() => {sessionStorage.clear(); navigate("../"+props.uid); }} className="btn btn-outline-primary"><i class="bi bi-arrow-counterclockwise"></i> Compila una nuova richiesta</button>
                </div>
            </div>

         
        </div>
    );
}

/*
<div className="col-md-6 py-3 text-center">
                    <a className="btn btn-outline-primary" href="https://www.scuolapark.it/"><i class="bi bi-link-45deg"></i> Vai su <b>www.scuolapark.it</b></a>
                </div>
*/